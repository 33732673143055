<template>
  <div class="Part3">
    <div class="w">
      <div class="titleBlack">
        <div>全终端覆盖, 统一数据管理</div>
      </div>
      <!-- <img src="@/assets/image/banner/terminal.png" alt="" /> -->
      <div class="box">
        <div class="one">
          <img
            src="@/assets/image/pic/1@2x.png"
            style="width: 135px; height: 249px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 153px; height: 69px"
            alt=""
          />
          <img
            src="@/assets/image/pic/xiaochengxu@2x.png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 315px;
              left: 31px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 313px;
              left: 64px;
            "
            >移动端</span
          >
        </div>
        <div class="two">
          <img
            src="@/assets/image/pic/22@2x.png"
            style="width: 315px; height: 253px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 231px; height: 69px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/pc@2x.png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 314px;
              left: 76px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 314px;
              left: 110px;
            "
            >销售管理PC端</span
          >
        </div>
        <div class="three">
          <img
            src="@/assets/image/pic/25@2x.png"
            style="width: 437px; height: 351px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 231px; height: 69px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/pc@2x (2).png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 415px;
              left: 150px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 415px;
              left: 192px;
            "
            >PC功能端</span
          >
        </div>
        <div class="four">
          <img
            src="@/assets/image/pic/23@2x.png"
            style="width: 246px; height: 173px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 173px; height: 69px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/pc@2x (1).png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 236px;
              left: 65px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 236px;
              left: 102px;
            "
            >活动PC端</span
          >
        </div>
        <div class="five">
          <img
            src="@/assets/image/pic/24@2x.png"
            style="width: 246px; height: 173px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 173px; height: 69px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/pc@2x (2).png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 236px;
              left: 56px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 236px;
              left: 90px;
            "
            >前端功能端</span
          >
        </div>
        <div class="six">
          <img
            src="@/assets/image/pic/shebei@2x.png"
            style="width: 186px; height: 249px"
            alt=""
          />
          <img
            src="@/assets/image/pic/box.png"
            style="width: 2px; height: 43px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/borderbox.png"
            style="width: 173px; height: 69px; margin: 0 auto"
            alt=""
          />
          <img
            src="@/assets/image/pic/pc@2x (2).png"
            style="
              width: 26px;
              height: 26px;
              position: absolute;
              top: 312px;
              left: 37px;
            "
            alt=""
          />
          <span
            style="
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: absolute;
              top: 310px;
              left: 73px;
            "
            >银联端</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part3 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  // background-color: #eef0fa;
  background-image: linear-gradient(to top, #fff, #e7eaf7);
  .titleBlack {
    margin-bottom: 160px;
  }
  img {
    width: 100%;
    display: block;
  }
  .box {
    width: 100%;
    height: 354px;
    position: relative;
  }
  .one {
    position: absolute;
  }
  .two {
    position: absolute;
    top: 0%;
    left: 13%;
  }
  .three {
    position: absolute;
    top: -28%;
    left: 43%;
  }
  .four {
    position: absolute;
    top: 22%;
    left: 34%;
  }
  .five {
    position: absolute;
    top: 22%;
    left: 70%;
  }
  .six {
    position: absolute;
    top: 0%;
    left: 93%;
  }
}
</style>
