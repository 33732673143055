<template>
  <div class="Part6">
    <div class="w">
      <div class="titleBlack">
        <div>一村合作伙伴</div>
      </div>
      <img src="@/assets/image/banner/part6@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part6 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background-color: #eef0fa;
  .titleBlack {
    margin-bottom: 160px;
  }
  img {
    width: 100%;
  }
}
</style>
