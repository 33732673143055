<template>
  <div class="home">
    <Swipper></Swipper>
    <Part1></Part1>
    <Part2></Part2>
    <Part3></Part3>
    <Part4></Part4>
    <Part5></Part5>
    <Part6></Part6>
    <Part7></Part7>
  </div>
</template>

<script>
import Part1 from '@/components/home/Part1.vue'
import Part2 from '@/components/home/Part2.vue'
import Part3 from '@/components/home/Part3.vue'
import Part4 from '@/components/home/Part4.vue'
import Part5 from '@/components/home/Part5.vue'
import Part6 from '@/components/home/Part6.vue'
import Part7 from '@/components/home/Part7.vue'
import Swipper from '@/components/home/Swipper.vue'
export default {
  name: 'Home',
  components: {
    Swipper,
    Part1,
    Part2,
    Part3,
    Part4,
    Part5,
    Part6,
    Part7
  },
  mounted() {
    window.scroll(0, 0)
  }
}
</script>
