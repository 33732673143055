<template>
  <div class="Part2">
    <div class="w">
      <div class="titleWhite">
        <div>一村科技多方面解决方案</div>
      </div>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >面向用户</button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >面向企业</button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
          >面向政府</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <div class="tabContainer">
            <div class="left">
              <div class="leftItem">
                <div>面向用户</div>
                <div>
                  为广大农户提供授信、农业保险、线上卖粮、农资交易等服务，
                  为农户解决“卖粮难”、“好粮卖不出价”等问题
                </div>
              </div>
              <div class="leftItemIcon">
                <div>
                  <img src="@/assets/image/sprite/shuzihua@2x.png">
                  <span>数字化管理</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/kefu@2x.png">
                  <span>24h客服服务</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/wulianwang@2x.png">
                  <span>设备物联网</span>
                </div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/image/banner/wosho@2x.png">
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <div class="tabContainer">
            <div class="left">
              <div class="leftItem">
                <div>面向企业</div>
                <div>提供一站式粮食收储、农资交易、采收记录、客户订单和库存管理，并提供全流程数据保全服务</div>
              </div>
              <div class="leftItemIcon">
                <div>
                  <img src="@/assets/image/sprite/jingxihua@2x.png">
                  <span>精细化管理</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/zhifu@2x.png">
                  <span>资金支付安全</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/duoshuju@2x.png">
                  <span>多业务数据融合</span>
                </div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/image/banner/qiye@2x.png">
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
          <div class="tabContainer">
            <div class="left">
              <div class="leftItem">
                <div>面向政府</div>
                <div>基于真实农业生产过程的大数据监管，对涉农企业的生产行为进行监管。</div>
              </div>
              <div class="leftItemIcon">
                <div>
                  <img src="@/assets/image/sprite/dashujuzhongxin@2x.png">
                  <span>大数据监管</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/Order@2x.png">
                  <span>产量统计管理</span>
                </div>
                <div>
                  <img src="@/assets/image/sprite/tudishouchu@2x.png">
                  <span>土地信息管理</span>
                </div>
              </div>
            </div>
            <div class="right">
              <img src="@/assets/image/banner/hezuo@2x.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part2 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background: url("../../assets/image/banner/bg@2x.png") center/cover no-repeat;
  color: #fff;
  .titleWhite {
    margin-bottom: 80px;
  }
  .nav-tabs {
    border: none;
  }
  .nav-item {
    flex: 1;
    width: 100%;
    border-bottom: 1px solid #ffffff66;
    button {
      margin: 0 auto;
      width: 50%;
      color: #fff;
      background-color: transparent;
      border: none;
      // border-bottom: 1px solid #ffffff66;
      font-size: 26px;
      line-height: 80px;
    }

    .active {
      border-bottom: 3px solid #2f54eb;
    }
  }
  .tabContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 400px;
      .leftItem {
        text-align: left;
        font-size: 22px;
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #ffffff;
        line-height: 40px;
        > div:first-child {
          font-size: 32px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #f9fafe;
          line-height: 40px;
          margin-bottom: 30px;
        }
      }
      .leftItemIcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          font-size: 24px;
        }
        img {
          height: 60px;
          margin-bottom: 40px;
        }
      }
    }
    .right {
      margin-left: 130px;
      img {
        height: 400px;
      }
    }
  }
}
</style>
