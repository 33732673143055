<template>
  <div class="Part5">
    <div class="w">
      <div class="titleWhite">
        <div>一切为了客户, 我们提供更具保障性的服务</div>
      </div>
      <!-- <img src="@/assets/image/banner/part5@2x.png" /> -->
     <div class="box">
      <div class="one">
        <img src="" alt=""/>
        <div class="box-one">专业运维保障</div>
        <div class ='box-rectangle'></div>
        <div class="box-two">高效的运维服务，保障您的多项业务
          有序稳定运行</div>
      </div>
      <div class="two">
        <img src="" alt=""/>
        <div class="box-one">定制化解决方案</div>
        <div class ='box-rectangle'></div>
        <div class="box-two">根据不同类型客户个性化业务需求，
        提供定制化服务 </div>
      </div>
      <div class="three">
        <img src="" alt=""/>
        <div class="box-one">百分之一百专业服务</div>
        <div class ='box-rectangle'></div>
        <div class="box-two">我们提供最贴心最专业的服务，快速
         有效地解决问题</div>
      </div>
     </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part5 {
  min-width: 1200px !important
  ;
  padding: 140px 0 100px;
  background: url('../../assets/image/banner/bg2.png') center/cover no-repeat;
  .titleWhite {
    margin-bottom: 160px;
  }
  .box{
    width: 100%;
    height: 477px;
    display: flex
  }
  .one{
    width: 33.33%;
    height: 100%;
   background-color: rgba(51, 48, 48, 0.23) !important;
    background-size: 18% !important;
    background: url("../../assets/image/partTwo/zhuanyehuwai@2x.png") no-repeat;
    background-position: 50% 16%;
  }
  .one:hover{
    width: 33.33%;
    height: 100%;
    background-color: #2f54eb!important;
    background-size: 18% !important;
    background: url("../../assets/image/partTwo/zhuanyehuwai@2x (1).png") no-repeat;
    background-position: 50% 16%;
     .box-rectangle{
    width: 10%;
    height: 4px;
     margin: 0px auto 29px;
    background-color: #F9FAFE;
  }
  }
  .two{
    width: 33.33%;
    height: 100%;
    background-color: rgba(51, 48, 48, 0.23) !important;
     background-size: 18% !important;
    background: url("../../assets/image/partTwo/dingzhi@2x (2).png") no-repeat;
    background-position: 50% 16%;
  }
  .two:hover{
    width: 33.33%;
    height: 100%;
     background-color: #2f54eb!important;
     background-size: 18% !important;
    background: url("../../assets/image/partTwo/dingzhi@2x.png") no-repeat;
    background-position: 50% 16%;
       .box-rectangle{
    width: 10%;
    height: 4px;
     margin: 0px auto 29px;
    background-color: #F9FAFE;
  }
  }
  .three{
    width: 33.33%;
    height: 100%;
   background-color: rgba(51, 48, 48, 0.23) !important;
     background-size: 18% !important;
    background: url("../../assets/image/partTwo/yunwei@2x.png") no-repeat;
    background-position: 50% 16%;
  }
  .three:hover{
    width: 33.33%;
    height: 100%;
    background-color: #2f54eb!important;
     background-size: 18% !important;
    background: url("../../assets/image/partTwo/yunwei@2x (2).png") no-repeat;
    background-position: 50% 16%;
    .box-rectangle{
    width: 10%;
    height: 4px;
     margin: 0px auto 29px;
    background-color: #F9FAFE;
  }
    }
  img{
    width: 18%;
    margin: 90px auto 41px
  }
  .box-one{
    font-size: 28px;
    font-weight: 500;
    color: #F9FAFE;
    margin: 43px auto 29px

  }
 
   .box-rectangle{
    width: 10%;
    height: 4px;
     margin: 0px auto 29px;
    background-color: #2F54EB;
  }
  .box-two{
    width: 90%;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC;
    font-weight: 100;
    color: #F9FAFE;
  }
}
</style>
