<template>
  <div class="Part1 w">
    <div class="titleBlack">
      <div>一村科技资讯</div>
    </div>
    <div class="content">
      <div class="left">
        <img src="@/assets/image/pic/news@2x.png" alt />
        <span class="po">一村动态</span>
        <span class="pp">了解一村科技官方最新动态资讯</span>
      </div>
      <div class="right">
        <!-- <div class="rightItem pointer" @click="to('/information')">
          <span
            >🔸 &nbsp;&nbsp;农业部、省农业厅领导莅临荃银高科、一村科技...</span
          >
          <span>2024年4月9日</span>
        </div> -->
        <div class="rightItem pointer" @click="to('/information')">
          <span
            >🔸 &nbsp;&nbsp;农业部、省农业厅领导莅临荃银高科、一村科...</span
          >
          <span>2024年4月9日</span>
        </div>
        <hr />
        <div class="rightItem pointer" @click="to('/information')">
          <span
            >🔸 &nbsp;&nbsp;一村科技承建“合肥虾通”龙虾产业互联网平台...</span
          >
          <span>2023年12月10日</span>
        </div>
        <hr />
        <div class="rightItem pointer" @click="to('/information')">
          <span>🔸 &nbsp;&nbsp;一村科技荣获“合肥市2023年优秀应用案例”...</span>
          <span>2023年9月4日</span>
        </div>
        <hr />
        <div class="rightItem pointer" @click="to('/information')">
          <span>🔸 &nbsp;&nbsp;一村科技荣获政府奖励,助力“种粮一体化...</span>
          <span>2023年8月21日</span>
        </div>
        <hr />

        <div class="rightItem pointer" @click="to('/information')">
          <span>🔸 &nbsp;&nbsp;皖美 光大种业贷产品上线仪式在合肥举行...</span>
          <span>2023年8月3日</span>
        </div>
        <hr />
        <div class="rightItem pointer" @click="to('/information')">
          <span
            >🔸 &nbsp;&nbsp;农发行赋能“种粮一体化”全产业链交流座谈会...</span
          >
          <span>2023年3月3日</span>
        </div>
        <hr />
        <div class="rightItem pointer" @click="to('/information')">
          <span>🔸 &nbsp;&nbsp;《“种粮一体化”共建项目》正式发布...</span>
          <span>2022年8月26日</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    to(path, query) {
      this.$router.push({
        path: "/Jump",
        query: { path, query },
      });
    },
    toLink(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="less" scoped>
.Part1 {
  padding: 140px 0 100px;
  .titleBlack {
    margin-bottom: 160px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      color: #33333388;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex: 1;
      position: relative;
      .po {
        position: absolute;
        top: 330px;
        left: 45px;
        font-size: 28px;
        font-weight: 550;
        color: #333333;
        font-family: PingFangSC;
      }
      .pp {
        position: absolute;
        top: 340px;
        left: 194px;
        font-size: 16px;
        color: #333333;
      }
    }
    .right {
      padding: 30px 50px;
      flex: 1;
      height: 460px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      hr {
        color: #999;
      }
      .rightItem {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span:last-child {
          color: #999;
        }
      }
    }
  }
}
</style>
