<template>
  <div class="Part4 w">
    <div class="titleBlack">
      <div>一村科技特色服务</div>
    </div>
    <!-- <img src="@/assets/image/banner/part4@2x.png" alt="" /> -->
    <div >
    <div class="box">
        <div style="width:400px">
        <img src="@/assets/image/pic/1@2x (1).png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">SaaS服务管理系统</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">独立部署基于服务商自身服务器的后台管理系统，数据化管理</p>
      </div>
     <div style="width:400px">
        <img src="@/assets/image/pic/3@2x.png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">移动端店铺助手</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">配备移动端管理后台，商户可以随时随地操作和管理商铺与订单</p>
      </div>
      <div style="width:400px">
        <img src="@/assets/image/pic/4@2x.png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">多渠道多终端覆盖</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">公众号+app+web等多渠商城覆盖，快速抢占农业市场</p>
      </div>
    </div>
      <div class="box">
         <div style="width:400px">
        <img src="@/assets/image/pic/6@2x.png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">7*24h全方位特定服务</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">24小时全方位定制服务，及时指导并解决问题，保证企业高效率工作运行</p>
      </div>
        <div style="width:400px">
        <img src="@/assets/image/pic/7@2x.png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">打通线上线下消费模式</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">线上商城+线下体验突破传统农业交易方式，赋能企业搭建社交新交易平台</p>
      </div>
        <div style="width:400px">
        <img src="@/assets/image/pic/8@2x.png" alt="" />
        <p style="font-size: 24px;font-weight: bold;margin:12px">定制化功能开发</p>
        <p style="font-size: 16px;font-weight: 400;margin:12px">专业的解决方案，提供一站式定制开发服务，满足个性化业务需求</p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.Part4 {
  min-width: 1100px !important
  ;
  padding: 140px 0 100px;
  .titleBlack {
    margin-bottom: 160px;
  }
  img {
    width: 145px;
    height: 171px;
  }
  .box{
    display: flex;
    flex: 1
  }
}
</style>
