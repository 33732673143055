<template>
  <div class="Part1 w">
    <div class="titleBlack">
      <div>一村科技农业产业互联网综合服务平台</div>
      <div class="tipBlack">全渠道全场景农业解决方案，助力农业产业打造智慧农业生态</div>
    </div>
    <!-- <img src="@/assets/image/banner/platform.png" alt="" /> -->
    <img src="@/assets/image/partTwo/pass@2x (1).png" alt>
    <div class="banner-box">
      <div class="top">
        <span class="titleft">粮食管理系统</span>
        <img src="@/assets/image/partTwo/passleft.png" alt>
        <span class="titright">销售管理系统</span>
        <img src="@/assets/image/partTwo/passright.png" alt>
      </div>
      <div class="bottom">
        <span class="textleft">安防系统</span>
        <img src="@/assets/image/partTwo/passleft.png" alt>
        <span class="textright">GIS地图+保险</span>
        <img src="@/assets/image/partTwo/passright.png" alt>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.Part1 {
  position: relative;
  padding: 140px 0 100px;
  .titleBlack {
    margin-bottom: 160px;
    font-weight: bold;
    font-size: 36px;
    .tipBlack {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333;
      /* line-height: 40px; */
      margin-top: 30px;
    }
  }
  img {
    width: 100%;
  }
  .top {
    position: absolute;
    top: 438px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    .titleft {
      position: absolute;
      top: 35px;
      left: 40px;
      font-size: 24px;
      font-weight: bold;
    }
    .titright {
      position: absolute;
      top: 35px;
      right: 60px;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .bottom {
    position: absolute;
    top: 872px;
    left: 0px;
    display: flex;
    justify-content: space-between;
    .textleft {
      position: absolute;
      top: 35px;
      left: 40px;
      font-size: 24px;
      font-weight: bold;
    }
    .textright {
      position: absolute;
      top: 35px;
      right: 60px;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .top img {
    width: 30%;
  }
  .bottom img {
    width: 30%;
  }
}
</style>
